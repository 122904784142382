<template>
  <!-- #region template -->
  <b-container fluid class="vh-100 overflow-hidden bg-white">
    <b-row class="h-100">
      <b-col cols="12" xl="3" class="p-5 d-flex flex-column justify-content-between">
        <div class="text-center">
          <cLogo />
        </div>
        <div>
          <!-- #region signIn -->
          <template v-if="$route.query.mode == 'signIn'">
            <h2 class="font-weight-bold mb-5">{{ $t('vUserManagement.signIn.title') }}</h2>
            <b-form @submit="signInWithEmailAndPassword">
              <b-form-group label="Email" label-class="font-weight-bold" label-for="email" description="We'll never share your email with anyone else.">
                <b-form-input id="email" autocomplete="username" v-model="form.email" type="email" required></b-form-input>
              </b-form-group>

              <b-form-group label="Password" label-class="font-weight-bold" label-for="current-password">
                <b-input-group>
                  <template #append>
                    <b-button variant="primary" v-on:click="togglePasswordVisibility"> <b-icon :icon="passwordVisible ? 'eye' : 'eye-slash'" aria-hidden="true"></b-icon> </b-button>
                  </template>
                  <b-form-input id="current-password" autocomplete="current-password" v-model="form.password" :type="passwordVisible ? 'text' : 'password'" required></b-form-input>
                </b-input-group>
              </b-form-group>
              <p class="text-danger">{{ error }}</p>
              <b-button type="submit" variant="primary" style="width: 100%">{{ $t('vUserManagement.signIn.button') }}</b-button>
            </b-form>
          </template>
          <!-- #endregion -->
          <!-- #region signOut -->
          <template v-if="$route.query.mode == 'signOut'">
            <div class="text-center">
              <template v-if="state == 0">
                <h2 class="font-weight-bold mb-3">{{ $t('vUserManagement.signOut.state[0].title') }}</h2>
                <p class="px-5 mb-4">{{ $t('vUserManagement.signOut.state[0].description') }}</p>
              </template>
              <template v-if="state == 1">
                <h2 class="font-weight-bold mb-3">{{ $t('vUserManagement.signOut.state[1].title') }}</h2>
                <p class="px-5 mb-4">{{ $t('vUserManagement.signOut.state[1].description') }}</p>
                <b-button to="?mode=signIn" class="px-5" type="submit" variant="dark">{{ $t('vUserManagement.signOut.state[1].button') }}</b-button>
              </template>
              <template v-if="state == -1">
                <h2 class="font-weight-bold mb-3">{{ $t('vUserManagement.signOut.state[2].title') }}</h2>
                <p class="px-5 mb-4">{{ $t('vUserManagement.signOut.state[2].description') }}</p>
                <p class="text-danger">{{ error }}</p>
                <b-button href="mailto:admin@reepco.dk?subject=RS-E-2335 - SignOut" class="px-5" type="submit" variant="dark">{{ $t('vUserManagement.signOut.state[2].button') }}</b-button>
              </template>
            </div>
          </template>
          <!-- #endregion -->
          <!-- #region resetPassword -->
          <template v-if="$route.query.mode == 'resetPassword'">
            <template v-if="state == 0">
              <h2 class="font-weight-bold mb-5">{{ $t('vUserManagement.resetPassword.state[0].title') }}</h2>
              <b-form @submit="sendPasswordResetEmail">
                <b-form-group label="Email" label-class="font-weight-bold" label-for="email" description="We'll never share your email with anyone else.">
                  <b-form-input id="email" v-model="form.email" type="email" required></b-form-input>
                </b-form-group>
                <p class="text-danger">{{ error }}</p>
                <b-button type="submit" variant="dark" style="width: 100%">{{ $t('vUserManagement.resetPassword.state[0].button') }}</b-button>
              </b-form>
            </template>
            <template v-if="state == 1">
              <div class="text-center">
                <h2 class="font-weight-bold mb-3">{{ $t('vUserManagement.resetPassword.state[1].title') }}</h2>
                <p class="px-5 mb-4">{{ $t('vUserManagement.resetPassword.state[1].description') }}</p>
              </div>
            </template>
            <template v-if="state == 2">
              <h2 class="font-weight-bold mb-5">{{ $t('vUserManagement.resetPassword.state[2].title') }}</h2>
              <b-form @submit="confirmPasswordReset">
                <b-form-group label="Password" label-class="font-weight-bold" label-for="password">
                  <b-input-group>
                    <template #append>
                      <b-button variant="primary" v-on:click="togglePasswordVisibility"> <b-icon :icon="passwordVisible ? 'eye' : 'eye-slash'" aria-hidden="true"></b-icon> </b-button>
                    </template>
                    <b-form-input id="password" v-model="form.password" :type="passwordVisible ? 'text' : 'password'" required></b-form-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group label="Repeat Password" label-class="font-weight-bold" label-for="password__repeat">
                  <b-input-group>
                    <template #append>
                      <b-button variant="primary" v-on:click="togglePasswordVisibility"> <b-icon :icon="passwordVisible ? 'eye' : 'eye-slash'" aria-hidden="true"></b-icon> </b-button>
                    </template>
                    <b-form-input id="password__repeat" v-model="form.password__repeat" :type="passwordVisible ? 'text' : 'password'" required></b-form-input>
                  </b-input-group>
                </b-form-group>
                <p class="text-danger">{{ error }}</p>
                <b-button type="submit" variant="dark" style="width: 100%">{{ $t('vUserManagement.resetPassword.state[2].button') }}</b-button>
              </b-form>
            </template>
            <template v-if="state == 3">
              <div class="text-center">
                <h2 class="font-weight-bold mb-3">{{ $t('vUserManagement.resetPassword.state[3].title') }}</h2>
                <p class="px-5 mb-4">{{ $t('vUserManagement.resetPassword.state[3].description') }}</p>
              </div>
            </template>
          </template>
          <!-- #endregion -->
        </div>
        <div>
          <!-- #region signIn -->
          <template v-if="$route.query.mode == 'signIn'">
            <router-link to="?mode=resetPassword" class="font-weight-bold text-primary">{{ $t('vUserManagement.signIn.link') }}</router-link>
          </template>
          <!-- #endregion -->
          <!-- #region resetPassword -->
          <template v-if="$route.query.mode == 'resetPassword'">
            <router-link to="?mode=signIn" class="font-weight-bold text-dark">{{ $t('vUserManagement.resetPassword.link') }}</router-link>
          </template>
          <!-- #endregion -->
        </div>
      </b-col>
      <b-col cols="9" class="p-0 d-none d-xl-block" style="overflow: hidden">
        <video class="bg-black w-100 h-100" style="transform: scale(1.2)" autoplay>
          <source src="@/assets/S22_reveal_02.mp4" type="video/mp4" />
        </video>
      </b-col>
    </b-row>
  </b-container>
  <!-- #endregion -->
</template>

<script>
// #region script
// #region import
import { getApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import cLogo from '@/components/cLogo.vue'
import { mapActions } from 'vuex'
// #endregion

// #region export
export default {
  name: 'vUserManagement.vue',
  components: {
    cLogo,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        password__repeat: '',
      },
      error: '',
      state: 0,
      passwordVisible: false,
    }
  },
  created() {
    this.update()
  },
  mounted() {
    document.querySelector('video').muted = true
    document.querySelector('video').play()
  },
  watch: {
    $route() {
      this.update()
    },
  },
  methods: {
    ...mapActions(['authentication/signInWithEmailAndPassword', 'authentication/signOut', 'authentication/sendPasswordResetEmail', 'authentication/confirmPasswordReset']),
    /**
     * Udates the component.
     * TODO can this be avoided (done only in created) if we force re-render the compontent on route change?
     */
    update() {
      this.state = 0
      this.error = ''
      switch (this.$route.query.mode) {
        case 'signIn':
          const unsubscribe = onAuthStateChanged(getAuth(getApp('secondary')), (user) => {
            if (user) {
              this.$router.push('/admin') //TODO redirect back to where you came from
              unsubscribe()
            }
          })
          break
        case 'signOut':
          this.signOut()
          break
        case 'resetPassword':
          if (this.$route.query.oobCode) {
            this.state = 2
          }
          break
        default:
          this.$router.push('/404')
          break
      }
    },
    /**
     * Asynchronously signs in using an email and password.
     * @fires signInWithEmailAndPassword
     * @async
     */
    signInWithEmailAndPassword(event) {
      event.preventDefault()
      this.error = ''
      this['authentication/signInWithEmailAndPassword']({
        email: this.form.email,
        password: this.form.password,
      }).catch((error) => {
        console.log(error.code)
        this.error = this.$t('vUserManagement.errors[0]')
      })
    },
    /**
     * Signs out the current user.
     * @fires signOut
     * @async
     */
    signOut() {
      this['authentication/signOut']()
        .then(() => {
          this.state = 1
        })
        .catch((error) => {
          this.error = this.$t('vUserManagement.errors[1]')
        })
    },
    /**
     * Sends a password reset email to the given email address.
     * @fires sendPasswordResetEmail
     * @async
     */
    sendPasswordResetEmail(event) {
      event.preventDefault()
      this.error = ''
      this['authentication/sendPasswordResetEmail']({
        email: this.form.email,
      })
        .then(() => {
          this.state = 1
        })
        .catch((error) => {
          this.error = error.code
        })
    },
    /**
     * Completes the password reset process, given a confirmation code and new password.
     * @fires confirmPasswordReset
     * @async
     */
    confirmPasswordReset(event) {
      event.preventDefault()
      this.error = ''
      if (this.form.password == this.form.password__repeat) {
        this['authentication/confirmPasswordReset']({
          oobCode: this.$route.query.oobCode,
          newPassword: this.form.password,
        })
          .then(() => {
            this.state = 3
          })
          .catch((error) => {
            this.error = error.code
          })
      } else {
        this.error = this.$t('vUserManagement.errors[2]')
      }
    },
    /**
     * Toggles the password visibility.
     */
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible
    },
  },
}
// #endregion
// #endregion
</script>
